<template>
  <div v-if="data">
    <v-btn
      color="primary"
      small
      class="mb-4"
      @click="addStage"
      v-if="stageQuantity<2"
    >
      增加期數
    </v-btn>

    <stage
      v-for="(stage, index) in data"
      @removeStage="removeStage"
      :key="index"
      :index="index"
      :dailyPerformanceOfLocation="dailyPerformanceOfLocation"
      v-model="data[index]"
    ></stage>
  </div>
</template>

<script lang="babel" type="text/babel">
import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  mixins: [formItemMixin],
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if(!Array.isArray(this.storeData)) {
        this.data = []
        return
      }

      this.data = window.eagleLodash.cloneDeep(this.storeData)
    },
    removeStage(index) {
      this.$apopup.base({
        title: this.$t('remove.confirm'),
        applyCallback: () => this.$delete(this.data, index),
      })
    },
    async addStage() {
      const stage = {
        panel_wp: 410,
        piece: 48,
        total_kwp: 0,
        rate: 4.5,
        yearly_income: 0,
      }
      this.data.push(stage)
      await this.$helper.delay(0.2)
      const $target = $(`*[plan-stage-index=${this.stageQuantity}]`)[0]
      this.$snotify.info(null, `已新增第${this.stageQuantity}期`)
      this.$vuetify.goTo($target)
    },
  },
  computed: {
    stageQuantity() {
      if(!Array.isArray(this.data)) return 0
      return this.data.length
    },
    dailyPerformanceOfLocation() {
      if(!this.formData) return 0
      const dailyPerformanceOfLocation = Number(this.formData.daily_performance_of_location)
      if(isNaN(dailyPerformanceOfLocation)) return 0
      return dailyPerformanceOfLocation
    },
  },
  watch: {
    data: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.data, this.storeData)) return
        this.sync()
      },
    },
    storeData: {
      deep: true,
      handler() {
        if(window.eagleLodash.isEqual(this.data, this.storeData)) return
        this.init()
      },
    },
  },
  components: {
    stage: () => import('./stage.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
